import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { NetworkConfigurationComponent } from "./network-configuration.component";
import { QStateInputComponentModule } from "../qstate-input/qstate-input.module";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponentModule } from "../svg-icon/svg-icon.module";
import { QStateSelectComponentModule } from "../qstate-select/qstate-select.module";

@NgModule({
    imports: [ 
        CommonModule, 
        IonicModule, 
        QStateInputComponentModule, 
        QStateSelectComponentModule,
        TranslateModule, 
        ReactiveFormsModule, 
        FormsModule, 
        SvgIconComponentModule
    ],
    declarations:   [ NetworkConfigurationComponent ],
    exports:        [ NetworkConfigurationComponent ]
})
export class NetworkConfigurationComponentModule {}