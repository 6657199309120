import { Injectable } from '@angular/core';
import { globals } from 'src/environments/environment';
import { Observable, of, forkJoin } from 'rxjs';
import { map, mergeMap, catchError, filter, switchMap } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class AlertApiService {

  constructor(private http: HttpClient) { }

  /**
   * retrieve all alert templates
   * @returns 
   */
  getAlertTemplates(): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/alerts/templates`).pipe(
      map(templates => templates),
      catchError(err => of(err))
    );
  }

  /**
   * method to get device alerts
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  getDeviceAlerts(workspaceId: string, deviceId: string): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/alerts/${workspaceId}/${deviceId}`).pipe(
      map(alerts => alerts),
      catchError(err => of(err))
    );
  }

  /**
   * set an alert for a device
   * @param workspaceId 
   * @param deviceId 
   * @param alertConfig 
   * @returns 
   */
  setDeviceAlert(workspaceId: string, deviceId: string, alertConfig: any): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/alerts/${workspaceId}/${deviceId}`, alertConfig).pipe(
      map(alertSet => alertSet),
      catchError(err => of(err))
    );
  }

  /**
   * method to set a quick alert for a device
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  setDeviceQuickAlert_V1(workspaceId: string, deviceId: string): Observable<any> {
    return this.getAlertTemplates().pipe(
      switchMap(templates => {
        let quickAlertTemplate = templates.find(tmpl => tmpl.templateId === "PERSONAL_QUICK_ALERT");
        quickAlertTemplate.isEnabled = true;
        quickAlertTemplate.name = "Quick Alert";

        return this.setDeviceAlert(workspaceId, deviceId, quickAlertTemplate).pipe(
          map(set => set)
        );
      })
    )
  }

  /**
   * method to set a quick alert for a device
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
   setDeviceQuickAlert(workspaceId: string, deviceId: string, data: any): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/alerts/${workspaceId}/${deviceId}/quick`, data).pipe(
      map(quick => quick)
    )
  }

  /**
   * method to update an existing alert config
   * @param workspaceId 
   * @param deviceId 
   * @param alertConfigId 
   * @returns 
   */
  updateDeviceAlert(workspaceId: string, deviceId: string, alertConfigId: string, data): Observable<any> {
    return this.http.put(`${globals.apiEndpoint}/alerts/${workspaceId}/${deviceId}/${alertConfigId}`, data).pipe(
      map(alertUpdated => alertUpdated),
      catchError(err => of(err))
    );
  }

  /**
   * method to delete an existing alert config
   * @param workspaceId 
   * @param deviceId 
   * @param alertConfigId 
   * @returns 
   */
   deleteDeviceAlert(workspaceId: string, deviceId: string, alertConfigId: string): Observable<any> {
    return this.http.delete(`${globals.apiEndpoint}/alerts/${workspaceId}/${deviceId}/${alertConfigId}`).pipe(
      map(alertDeleted => alertDeleted),
      catchError(err => of(err))
    );
  }

  /**
   * method to get all user notifications
   * @returns 
   */
  getNotifications(): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/alerts/notifications`).pipe(
      map(notifications => notifications)
    );
  }

  /**
   * marks a notification as read
   * @param notificationId 
   * @returns 
   */
  setNotificationAsRead(notificationId: string): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/alerts/notifications/${notificationId}`, {}).pipe(
      map(read => read)
    );
  }

  /**
   * clear all notifications a notification as read
   * @returns 
   */
   clearAllNotifications(): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/alerts/notifications/updateAll?action=delete`, {}).pipe(
      map(cleared => cleared)
    );
  }

  /**
   * clear all notifications a notification as read
   * @returns 
   */
   markAllNotificationsAsRead(): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/alerts/notifications/updateAll?action=read`, {}).pipe(
      map(marked => marked)
    );
  }

  /**
   * subscribe for notifications with an fcm token
   */
  subscribeForNotifications(token: string): Observable<any> {
    const payload = {
      "FCMToken": token
    };
    return this.http.post(`${globals.apiEndpoint}/alerts/notifications/subscribe`, payload).pipe(
      map(subscribed => subscribed),
      catchError(err => of(err))
    );
  }
}
