import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QStateSelectComponent } from "./qstate-select.component";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponentModule } from '../svg-icon/svg-icon.module';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports:        [ CommonModule, FormsModule, IonicModule, SvgIconComponentModule, TranslateModule ],
    declarations:   [ QStateSelectComponent ],
    exports:        [ QStateSelectComponent ]
})
export class QStateSelectComponentModule {}