import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ColorItem } from 'src/app/interfaces/color-item.interface';
import { VisualizationItem } from 'src/app/interfaces/visualization-item.interface';
import { VisualizationSettingsPopoverComponent } from '../visualization-settings-popover/visualization-settings-popover.component';
import { ConfigurationWizzardEnum } from 'src/app/enums/configuration-wizzard.enum';
import { QStateStoreEnum } from 'src/app/store/qstate.enum.store';
import { QStateStore } from 'src/app/store/qstate.main.store';
import { NotificationTypeEnum } from 'src/app/enums/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { lightColors } from 'src/environments/environment';
import { BluetoothService } from 'src/app/services/bluetooth.service';

@Component({
  selector: 'visualization-configuration',
  templateUrl: './visualization-configuration.component.html',
  styleUrls: ['./visualization-configuration.component.scss'],
})
export class VisualizationConfigurationComponent implements OnInit, OnDestroy {

  @Input() connectedDevice: any;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onStep: EventEmitter<ConfigurationWizzardEnum> = new EventEmitter<ConfigurationWizzardEnum>(null);

  get isItemSelected(): boolean {
    return this.visualizationItems.some(visItem => visItem.selected);
  }

  get saveAvailable(): boolean {
    return this.visualizationItems.some(visItem => visItem.colorItem && visItem.configuration);
  }

  subscriptions: Subscription[] = [];

  colorItems: ColorItem[] = [];
  visualizationItems: VisualizationItem[] = [
    {
      colorItem: null,
      selected: true,
      order: 0,
    }
  ];

  constructor(
    private popoverController: PopoverController, 
    private store: QStateStore, 
    private translate: TranslateService,
    private bluetoothService: BluetoothService
  ) { }

  ngOnInit() {
    this.defineColorItems();
    
    if (_.get(this.connectedDevice, 'existingLightConfig')) {
      this.preFillExistingLightConfig(_.get(this.connectedDevice, 'existingLightConfig.lightConfig', []));
    }

    this.bluetoothService.disconnectFromDeviceWithId(this.connectedDevice.id);

    console.log("connectedDevice", this.connectedDevice);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private preFillExistingLightConfig(existingConfig: any): void {
    let mappedVisualItems = existingConfig.map(configItem => {

      const matchedColorItem = this.colorItems.find(ci => ci.segmentValue === configItem.segment);
      let colorConfig = {};
      // onState
      if (_.get(configItem, 'onState')) {
        colorConfig["onState"] = _.get(configItem, 'onState');
      }

      // offState
      if (_.get(configItem, 'offState')) {
        colorConfig["offState"] = _.get(configItem, 'offState');
      }

      // blinkingState
      if (_.get(configItem, 'blinkingState')) {
        colorConfig["blinkingState"] = _.get(configItem, 'blinkingState');
      }

      return {
        order: configItem.order,
        selected: false,
        colorItem: {
          segmentValue: configItem.segment,
          segmentColor: matchedColorItem.segmentColor,
          defined: true,
        },
        configuration: colorConfig
      }
    });

    mappedVisualItems = mappedVisualItems.sort((vA, vB) => {
      return vA.order - vB.order;
    });

    this.visualizationItems = mappedVisualItems;

    this.setDefinedColorItems();
  }

  private defineColorItems(): void {
    this.colorItems = [
      {
        segmentValue: 'RED',
        segmentColor: lightColors.RED
      },
      {
        segmentValue: 'YELLOW',
        segmentColor: lightColors.YELLOW
      },
      {
        segmentValue: 'GREEN',
        segmentColor: lightColors.GREEN
      },
      {
        segmentValue: 'BLUE',
        segmentColor: lightColors.BLUE
      },
      {
        segmentValue: 'WHITE',
        segmentColor: lightColors.WHITE
      }
    ]
  }

  selectVisualizationItem(visualizationItem: VisualizationItem): void {
    this.visualizationItems.forEach(visItem => visItem.selected = false);
    visualizationItem.selected = true;
  }

  addVisualizationItem(): void {
    const order = this.visualizationItems.length + 1;
    this.visualizationItems.forEach(visItem => visItem.selected = false);

    this.visualizationItems.push(
      {
        colorItem: null,
        selected: true,
        order: order
      }
    );

    this.reorderVisualizationItems();
  }

  addColorToItem(colorItem: ColorItem): void {
    const alreadyMappedWithSame = this.visualizationItems.find(visItem => {
      if (visItem.colorItem) {
        return visItem.colorItem.segmentValue === colorItem.segmentValue;
      }

      return false;
    });

    if (alreadyMappedWithSame) {
      alreadyMappedWithSame.colorItem = null;
      alreadyMappedWithSame.configuration = null;
    }

    const selectedVisualizationItem = this.visualizationItems.find(visItem => visItem.selected);
    selectedVisualizationItem.colorItem = colorItem;

    this.setDefinedColorItems();

    // open config popover instant
    this.openVisualizationSettingsPopover(selectedVisualizationItem);
  }

  removeVisualizationItem(item: VisualizationItem): void {
    const mappedItem: VisualizationItem = _.remove(this.visualizationItems, function (itm) {
      return itm.order === item.order;
    });

    this.setDefinedColorItems();
    this.reorderVisualizationItems();
  }

  private reorderVisualizationItems(): void {
    this.visualizationItems.forEach((item, key) => {
      item.order = key;
    });
  }

  private setDefinedColorItems(): void {
    this.colorItems.forEach(colorItem => {
      colorItem.defined = false;
    });

    this.visualizationItems.forEach(visItem => {
      if (visItem.colorItem) {
        const foundColorItem = this.colorItems.find(colorItem => {
          return visItem.colorItem.segmentValue === colorItem.segmentValue;
        });

        if (foundColorItem) {
          foundColorItem.defined = true;
        }
      }
    })
  }

  getSegmentStyle(colorItem: ColorItem): any {
    if (colorItem.defined) {
      return {
        'border': `2px dashed ${colorItem.segmentColor}`,
        'background-color': `${colorItem.segmentColor}30`,
      };
    }
    else {
      return {
        'background-color': colorItem.segmentColor,
        'border': `2px dashed ${colorItem.segmentColor}`
      };
    }
  }

  async openVisualizationSettingsPopover(visualizationItem: VisualizationItem) {
    const popover = await this.popoverController.create({
      component: VisualizationSettingsPopoverComponent,
      cssClass: 'qstate-popover visualization-popover',
      translucent: true,
      componentProps: {
        configuration: _.get(visualizationItem, 'configuration'),
        colorItem: _.get(visualizationItem, 'colorItem')
      }
    });
    popover.present();

    return await popover.onWillDismiss().then(data => {
      if (_.get(data, 'data.lightConfig')) {
        visualizationItem.configuration = _.get(data, 'data.lightConfig');
      }
    });
  }

  back(): void {
    this.onStep.emit(ConfigurationWizzardEnum.Network);
  }

  saveDeviceLightConfig(): void {
    const mappedDevices = [];
    let order = 0;
    this.visualizationItems.forEach(visItem => {
      if (visItem.colorItem && visItem.configuration) {
        const base = {
          order: order,
          segment: visItem.colorItem.segmentValue,
        };

        mappedDevices.push(
          _.merge({}, base, visItem.configuration)
        );

        order++;
      }
    });

    if (mappedDevices && mappedDevices.length) {
      this.onSubmit.emit(mappedDevices);
    }
    else {
      this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
        type: NotificationTypeEnum.Error,
        message: this.translate.instant('PAGE_DEVICE_CONFIGURATION.NO_ITEM_DEFINED')
      });
    }
  }
}
