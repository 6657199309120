import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SvgIconComponentModule } from 'src/app/components/svg-icon/svg-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { HealthCheckModal } from './health-check.modal';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    SvgIconComponentModule,
    TranslateModule
  ],
  declarations: [HealthCheckModal],
  exports: [HealthCheckModal]
})
export class HealHealthCheckModalModule {}
