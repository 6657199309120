import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { globals } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {

  constructor(private http: HttpClient) {}

  addNewUser(userData): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/admin/user`, userData).pipe(
      map(resp => {
        return resp;
      })
    )
  }

  getUserProfile(): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/admin/user/profile`).pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  deleteUserProfile(): Observable<any> {
    return this.http.delete(`${globals.apiEndpoint}/admin/user`).pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  updateUserProfile(userData): Observable<any> {
    return this.http.put(`${globals.apiEndpoint}/admin/user/profile`, userData).pipe(
      map(resp => {
        return resp;
      })
    )
  }

  createNewWorkspace(workspaceData): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/admin/workspaces`, workspaceData).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  deleteWorkspace(workspaceId: string): Observable<any> {
    return this.http.delete(`${globals.apiEndpoint}/admin/workspaces/${workspaceId}`).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  leaveWorkspace(workspaceId): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/admin/workspaces/${workspaceId}/leave`, {}).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  updateWorkspace(workspaceId: string, workspaceData: any): Observable<any> {
    return this.http.put(`${globals.apiEndpoint}/admin/workspaces/${workspaceId}`, workspaceData).pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        return of(err)
      })
    );
  }

  setWorkspaceAsDefault(workspaceId: string): Observable<any> {
    return this.http.put(`${globals.apiEndpoint}/admin/workspaces/${workspaceId}/default`, {}).pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        return of(err)
      })
    );
  }

  getWorkspaceJoinToken(workspaceId: string): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/admin/workspaces/${workspaceId}/token`).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  addToWorkspaceWithJoinToken(token: string): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/admin/workspaces/join/${token}`, {}).pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  getAllWorkspacesOfCurrentUser(): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/admin/workspaces`).pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  /**
   * method to get a workspace by id
   * @param workspaceId 
   */
  getWorkspaceById(workspaceId: string): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/admin/workspaces/${workspaceId}`).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  /**
   * method for adding a device to a license
   * @param deviceId
   * @param activationCode
   * @returns
   */
   addDeviceToLicense(deviceId: string, activationCode: string): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/devices/${deviceId}/license/install/${activationCode}`, {}).pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => {
        return of(err);
      })
    );
  }

  /**
   * method to removing a license from a device
   * @param deviceId
   * @returns
   */
   removeDeviceToLicense(deviceId: string): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/devices/${deviceId}/license/remove`, { reason: "remove" }).pipe(
      map(resp => {
        return resp;
      })
    );
  }

}
