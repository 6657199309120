import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { QStateStore } from '../store/qstate.main.store';
import { QStateStoreEnum } from '../store/qstate.enum.store';
import { Notification } from '../interfaces/notification.interface';
import { NotificationTypeEnum } from '../enums/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AppErrorHandlerHelper implements ErrorHandler {

    constructor(private store: QStateStore, private translate: TranslateService) { }

    handleError(error: Error | HttpErrorResponse | string) {
        
        if (error instanceof HttpErrorResponse) {
            
            let errorMessage = _.get(error, 'error.key');
            if (!errorMessage) {
                errorMessage = _.get(error, 'message', 'HTTP Error Occured');
            }
            errorMessage = this.hasTranslation(errorMessage) ?  this.translate.instant("ERROR_MESSAGES."+errorMessage) : errorMessage;
            const statusCode = _.get(error, 'status');
            let message: Notification;
            
            switch (statusCode) {
                case 401:
                    message = { //todo erweitern
                        type: NotificationTypeEnum.Warn,
                        message: errorMessage
                    };
                    break;
                default:
                    message = {
                        type: NotificationTypeEnum.Error,
                        message: errorMessage
                    };
                    break;
            }

            this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, message);
            this.store.dispatch(QStateStoreEnum.LOADING, false);

        } 
        // else if (Object.getPrototypeOf(error).name === "Error") { //Firebase Errors
        else if (_.get(error, 'code') && error.toString().indexOf("ExpressionChangedAfterItHasBeenCheckedError") === -1) { //Firebase Errors
            let message: Notification = {
                type: NotificationTypeEnum.Error,
                message: _.get(error, 'message', error)
            };

            this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, message);
            this.store.dispatch(QStateStoreEnum.LOADING, false);
        } 
        else {
            console.error(error);
        }
    }

    hasTranslation(key: string): boolean {
        const translation = this.translate.instant("ERROR_MESSAGES."+key);
        return translation !== key && translation !== '';
    }
}