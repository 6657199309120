export enum BleCharacteristicEnum {
    WifiSsid0 = "0ae12c78-4a91-47d2-ba37-21a66015e000",
    WifiSsid1 = "0ae12c78-4a91-47d2-ba37-21a66015e001",
    WifiSsid2 = "0ae12c78-4a91-47d2-ba37-21a66015e002",
    WifiSsid3 = "0ae12c78-4a91-47d2-ba37-21a66015e003",
    WifiSsid4 = "0ae12c78-4a91-47d2-ba37-21a66015e004",
    WifiSsid5 = "0ae12c78-4a91-47d2-ba37-21a66015e005",
    WifiSsid6 = "0ae12c78-4a91-47d2-ba37-21a66015e006",
    WifiSsid7 = "0ae12c78-4a91-47d2-ba37-21a66015e007",
    WifiSsid8 = "0ae12c78-4a91-47d2-ba37-21a66015e008",
    WifiSsid9 = "0ae12c78-4a91-47d2-ba37-21a66015e009",
    ConnectionWifiSsid = "2646e9d9-f21b-410e-89eb-fd32867281c5",
    ConnectionWifiPassword = "8b4f7571-0141-4e1f-8186-4add7ce1dec6",
    ConnectionWifiStatus = "a5f166ad-33f2-4a53-a964-83361298100e",
    MqttProjectId = "b3dfc9e3-4e8e-4685-a30f-dd73bf3874fb",
    MqttLocation = "58c2b176-7d4c-468c-807d-75c47b1637bd",
    MqttRegistryId = "a1ea512f-792d-4f68-ad3f-912770c96376",
    MqttDeviceId = "3f414b50-9529-4b48-80db-c628c776c824",
    MqttPrivateKey = "b6fcf13f-4b38-40fa-a525-3967279fec9d",
    CellularStatus = "08b6c3df-f1a1-401f-8955-310e96718151"
}