import { Injectable } from '@angular/core';
import { globals } from 'src/environments/environment';
import { Observable, of, forkJoin, pipe } from 'rxjs';
import { map, mergeMap, catchError, filter, switchMap } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { DeviceProgressStateEnum } from '../enums/device-progress-state.enum';
import { LicenseType } from '../enums/license-type.enum';


@Injectable({
  providedIn: 'root'
})

export class DeviceApiService {

  constructor(private http: HttpClient) { }

  /**
   * get devices by workspace
   * @param workspaceId 
   * @returns 
   */
  getDevicesByWorkspaceId(workspaceId): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/devices/${workspaceId}`).pipe(
      map(resp => {
        return resp;
      }),
      catchError(err => of(err))
    );
  }

  /**
   * get devices by workspace including license information
   * @param workspaceId 
   * @returns 
   */
  getDevicesByWorkspaceIdExtended(workspaceId): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/devices/${workspaceId}`).pipe(
      switchMap(
        (devices: any[]) => {
          if (devices && devices.length) {
            return this.getDeviceLicenses(devices as any).pipe(
              map((licences) => {
                devices.forEach((dev, key) => {
                  dev.license = licences[key];

                  // set license to DEMO_ACTIVE on DEMO_AVAILABLE
                  if (dev.license.license === LicenseType.DemoAvailable) {
                    dev.license.license = LicenseType.DemoActive;
                  }

                });

                return devices;
              })
            )
          }
          else {
            return of([])
          }
        }
      )
    );
  }

  /**
   * get licenses from a device list
   * @param devices 
   * @returns 
   */
  getDeviceLicenses(devices: any[]): Observable<any> {
    const observables: Observable<any>[] = devices.map(device =>
      // this.http.get(`${globals.apiEndpoint}/devices/${device.deviceId}/license`).pipe(
      this.http.get(`${globals.apiEndpoint}/devices/licenseCheck/${device.deviceId}`).pipe(
        catchError(err => of(err))
      )
    );

    return forkJoin(
      observables
    ).pipe(
      map(licenses => {
        return licenses.reduce((acc, val) => acc.concat(val), [])
      })
    );
  }

  /**
   * get single device license
   * @param deviceId 
   * @returns 
   */
  getSingleDeviceLicense(deviceId: string): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/devices/licenseCheck/${deviceId}`).pipe(
      map(license => license),
      catchError(err => of(err))
    );
  }

  /**
   * get device by device id within a workspace
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  getDevice(workspaceId: string, deviceId: string): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}`).pipe(
      map(device => device),
      catchError(err => of(err))
    );
  }

  /**
   * get device by device id within a workspace with license information
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  getDeviceExtended(workspaceId: string, deviceId: string): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}`).pipe(
      switchMap(
        (device: any) => {
          if (device) {
            return this.getDeviceLicenses([device]).pipe(
              map((licences) => {
                device.license = licences[0];

                // set license to DEMO_ACTIVE on DEMO_AVAILABLE
                if (device.license.license === LicenseType.DemoAvailable) {
                  device.license.license = LicenseType.DemoActive;
                }

                return device;
              })
            )
          }
          else {
            return of([])
          }
        }
      )
    );
  }

  /**
   * add new workspace device
   * @param workspaceId 
   * @param data 
   * @returns 
   */
  addDevice(device: any, workspaceId: string, data: any, forceOverride: boolean = false): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/devices/${workspaceId}?forceOverride=${forceOverride}`, data).pipe(
      switchMap((added: any) => {
        return this.setDeviceConfigureProgressState(workspaceId, device.id, DeviceProgressStateEnum.Created).pipe(
          map(state => state)
        );
      }),
      catchError(err => of(err))
    );
  }

  /**
   * update a workspace device
   * @param workspaceId 
   * @param deviceId 
   * @param data 
   * @returns 
   */
  updateDevice(workspaceId: string, deviceId: string, data: any): Observable<any> {
    return this.http.put(`${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}`, data).pipe(
      map(updated => updated),
      catchError(err => of(err))
    );
  }

  /**
   * update a device firmware
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  updateDeviceFirmware(workspaceId: string, deviceId: string): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}/updateFirmware`, {}).pipe(
      map(updated => updated),
      catchError(err => of(err))
    );
  }

  /**
   * delete a workspace device
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  deleteDevice(workspaceId: string, deviceId: string): Observable<any> {
    return this.http.delete(`${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}`).pipe(
      map(deleted => deleted),
    );
  }

  /**
   * method to retrieve an existing light config from a device
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  getDeviceLightConfig(workspaceId: string, deviceId: string): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}/lightConfig`).pipe(
      map(config => config),
      catchError(err => of(err))
    );
  }

  /**
   * method to retrieve an existing mqtt config from a device
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  getDeviceSingleConfiguration(workspaceId: string, deviceId: string): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}/configuration`).pipe(
      map(config => config),
      catchError(err => of(err))
    );
  }

  /**
   * method to retrieve an existing device including the light config
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  getDeviceWithLightConfig(workspaceId: string, deviceId: string): Observable<any> {
    const device = this.getDevice(workspaceId, deviceId);
    const lightConfig = this.getDeviceLightConfig(workspaceId, deviceId);
    const deviceConfiguration = this.getDeviceSingleConfiguration(workspaceId, deviceId);
    const deviceLicense = this.getSingleDeviceLicense(deviceId);

    return forkJoin({
      device: device,
      lightConfig: lightConfig,
      deviceConfiguration: deviceConfiguration,
      deviceLicense: deviceLicense
    });
  }

  /**
   * method to add a light config to a device
   * @param workspaceId 
   * @param deviceId 
   * @param data 
   * @returns 
   */
  addLightConfigToDevice(workspaceId: string, deviceId: string, data: any): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}/lightConfig`, data).pipe(
      switchMap((lightConfig: any) => {
        return this.setDeviceConfigureProgressState(workspaceId, deviceId, DeviceProgressStateEnum.Configured).pipe(
          map(state => state)
        );
      }),
      catchError(err => of(err))
    );
  }

  /**
   * refresh all device states
   * @param workspaceId 
   * @returns 
   */
  refreshDeviceStates(workspaceId: string): Observable<any> {
    return this.http.post(`${globals.apiEndpoint}/devices/${workspaceId}/refresh`, {}).pipe(
      map(refresh => refresh),
      catchError(err => of(err))
    );
  }

  /**
   * set the device configure state
   * @param workspaceId 
   * @param deviceId 
   * @param data 
   */
  setDeviceConfigureProgressState(workspaceId: string, deviceId: string, state: DeviceProgressStateEnum): Observable<any> {
    return this.http.post(
      `${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}/configuration/progress`,
      { configurationProgress: state })
      .pipe(
        map(refresh => refresh),
        catchError(err => of(err))
      );
  }

  /**
   * get the device hardware variables
   * @param workspaceId 
   * @param deviceId 
   * @returns 
   */
  getDeviceVariables(workspaceId: string, deviceId: string): Observable<any> {
    return this.http.get(`${globals.apiEndpoint}/devices/${workspaceId}/${deviceId}/variables`).pipe(
      map(variables => variables),
      // catchError(err => of(err))
    );
  }
}
