import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,

} from '@angular/common/http';
import {from} from "rxjs/index";
import { AuthService } from '../services/auth.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        // skip intercept if "preventIntercept" is set in HttpHeader
        const preventIntercept = request.headers.get("preventIntercept");
        if (preventIntercept) {
            return next.handle(request);
        }

        const token = this.authService.getExistingToken();
        
        if (token != null && !this.authService.isTokenExpired()) { //header mit Token anreichern
            request = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + token)});
            return next.handle(request);
        }
        // else if (token != null && this.authService.isTokenExpired()) { //header mit Token anreichern
        //     console.log("token expired");
        // }
        else {
            return from(this.handle(request, next))
        }
    }

    async handle(request: HttpRequest<any>, next: HttpHandler) {
        let tokenReady = await this.authService.getNewToken(request);
        if (tokenReady) {
            const token = this.authService.getExistingToken();
            request = request.clone({headers: request.headers.set('Authorization', 'Bearer ' + token)});
        }
        return next.handle(request).toPromise();
    }
}
