import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ConfigurationModal } from './configuration.modal';
import { NetworkConfigurationComponentModule } from 'src/app/components/network-configuration/network-configuration.module';
import { DefaultConfigurationComponentModule } from 'src/app/components/default-configuration/default-configuration.module';
import { VisualizationConfigurationComponentModule } from 'src/app/components/visualization-configuration/visualization-configuration.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NetworkConfigurationComponentModule,
    DefaultConfigurationComponentModule,
    VisualizationConfigurationComponentModule
  ],
  declarations: [ConfigurationModal],
  exports: [ConfigurationModal]
})
export class ConfigurationModalPageModule {}
