import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, ToastController, Platform, AlertController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { QStateStoreEnum } from './store/qstate.enum.store';
import { QStateStore } from './store/qstate.main.store';
import * as _ from 'lodash';
import { Notification } from './interfaces/notification.interface';
import { TranslateService } from '@ngx-translate/core';
import { AlertApiService } from './services/alert-api.service';
import { NotificationTypeEnum } from './enums/notification-type.enum';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {

  subscriptions: Subscription[] = [];
  loadingAnimation: HTMLIonLoadingElement;
  errorToast: HTMLIonToastElement;
  loaderHideStackEnabled: boolean = false;

  constructor(
    private store: QStateStore,
    private translate: TranslateService,
    public toastController: ToastController,
    public loadingController: LoadingController,
    private platform: Platform,
    private alertApiService: AlertApiService,
    private alertController: AlertController,
    private modalController: ModalController) {

    // prevent back button on android os
    this.platform.ready().then(() => {
      this.platform.backButton.subscribeWithPriority(9999, () => {
        document.addEventListener('backbutton', function (event) {
          event.preventDefault();
          event.stopPropagation();
        }, false);
      });
    });

    this.translate.setDefaultLang('de');
    this.translate.use('de');

    // test store ie for global notifications
    const loaderSub = this.store.select(QStateStoreEnum.LOADING).subscribe(loading => {
      if (loading) {
        if (_.get(loading, 'message')) {
          this.presentLoading(_.get(loading, 'message'));
        }
        else {
          this.presentLoading();
        }
      } else {
        if (this.loadingAnimation) {
          setTimeout(() => {
            this.loadingAnimation.dismiss();
          });
        }
        this.loaderHideStackEnabled = true;
      }
    });
    this.subscriptions.push(loaderSub);

    // check for global notifications
    const notificationSub = this.store.select(QStateStoreEnum.NOTIFICATIONS).subscribe(notification => {
      if (notification) {
        this.showNotification(notification);
      }
    });

    this.subscriptions.push(notificationSub);

    // check for global alerts
    const notificationAlertSub = this.store.select(QStateStoreEnum.ALERTS).subscribe(alert => {
      if (alert) {
        this.showAlertNotification(alert);
      }
    });

    this.subscriptions.push(notificationAlertSub);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  showNotification(notification: Notification): void {
    this.presentToast(notification);
  }

  showAlertNotification(message: { title: string, body: string, detailLink?: string }): void {
    this.presentAlert(message);
  }

  async presentToast(notification: Notification) {

    try {
      this.errorToast.dismiss();
    } catch (e) { }

    this.errorToast = await this.toastController.create({
      message: notification.message,
      cssClass: notification.type,
      duration: 3000
    });
    this.errorToast.present();
  }

  async presentLoading(message?: string) {
    this.loaderHideStackEnabled = false;
    if (message) {
      this.loadingAnimation = await this.loadingController.create({
        message: message
      });
    }
    else {
      this.loadingAnimation = await this.loadingController.create();
    }
    await this.loadingAnimation.present().then(() => {
      if (this.loaderHideStackEnabled) {
        this.loadingAnimation.dismiss();
      }
    });
  }

  async presentAlert(message: { title: string, body: string, detailLink?: string }) {
    let buttons = [];
    if (message.detailLink) {
      buttons = [
        {
          text: this.translate.instant('FORMS.DETAILS'),
          handler: () => {
            this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
              type: NotificationTypeEnum.Warn,
              message: "Deep Linking is not implemented"
            });
          }
        }
      ];
    }
    else {
      buttons = [
        {
          text: this.translate.instant('FORMS.OK'),
          handler: () => {
            this.store.dispatch(QStateStoreEnum.UPDATE_GUI, { loadDevices: true })
          }
        }
      ];
    }

    const alert = await this.alertController.create({
      cssClass: 'notification-alert',
      header: message.title,
      message: message.body,
      buttons: buttons,
      backdropDismiss: false
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  private unsubscribeAll(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
