import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { StateEnum } from 'src/app/enums/state.enum';
import { QStateStore } from 'src/app/store/qstate.main.store';
import * as _ from 'lodash';
import { QStateStoreEnum } from 'src/app/store/qstate.enum.store';
import { NotificationTypeEnum } from 'src/app/enums/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { isObject } from 'util';

@Component({
  selector: 'visualization-settings-popover',
  templateUrl: './visualization-settings-popover.component.html',
  styleUrls: ['./visualization-settings-popover.component.scss'],
})
export class VisualizationSettingsPopoverComponent implements OnInit {

  get disabledAdd(): boolean {
    return this.displayRows.some(row => !row.state.value);
  }

  inputs: string[] = ["onState", "blinkingState", "offState"];
  states: StateEnum[] = [];
  colorItem: any; 
  displayRows: { input: FormControl, state: FormControl }[];

  constructor(
    public router: Router,
    private store: QStateStore,
    private popoverController: PopoverController,
    private navParams: NavParams,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    const existingConfig = _.get(this.navParams, 'data.configuration', null);
    this.states = Object.keys(StateEnum).map(key => StateEnum[key]);
    this.states = this.states.filter(state => state !== StateEnum.Offline && state !== StateEnum.Online);

    this.colorItem = _.get(this.navParams, 'data.colorItem', null);
   
    if (existingConfig) {
      this.displayRows = Object.keys(existingConfig).map(key => {
        return {
          input: new FormControl(key),
          state: new FormControl(existingConfig[key])
        }
      });
    }
    else {
      let presetValue:string; 
      switch(this.colorItem.segmentValue) { 
        case "RED": { 
          presetValue = "FAILURE"
          break; 
        }
        case "YELLOW": { 
          presetValue = "WARNING"
          break; 
        } 
        case "GREEN": { 
          presetValue = "NORMAL_OPERATION"
          break; 
        } 
        case "BLUE": { 
          presetValue = "USER_ACTION_REQUIRED"
          break; 
        } 
        case "WHITE": { 
          presetValue = "MISC"
          break; 
        }
        default: { 
          presetValue = "FAILURE"
          break;  
        } 
      } 
      this.displayRows = [
        {
          input: new FormControl('onState'),
          state: new FormControl(presetValue)
        }
      ];
    }
    if (this.displayRows.length < 3) {
      this.fillUpDisplayRows();
    }

    this.orderDisplayRows();
  }

  orderDisplayRows() {
    let onStateObject;
    let offStateObject;
    let blinkingStateObject;
    this.displayRows.forEach(element => {
      if (element.input.value === "onState" ) {
        onStateObject = element;
      }
      if (element.input.value === "offState" ) {
        offStateObject = element;
      }
      if (element.input.value === "blinkingState" ) {
        blinkingStateObject = element;
      }
    });
    this.displayRows[0] = onStateObject;
    this.displayRows[1] = offStateObject;
    this.displayRows[2] = blinkingStateObject;
  }

  fillUpDisplayRows(): void {
    let existingStates = [];
    this.displayRows.forEach(displayRow => {
      existingStates.push(displayRow.input.value);
    });
    let missingStates = [];
    this.inputs.forEach(element => {
      if (!existingStates.includes(element)) {
        missingStates.push(element);
      }
    });

    missingStates.forEach(element => {
      this.displayRows.push({
        input: new FormControl(element),
        state: new FormControl(null)
      });
    }); 
  }

  removeDisplayOption(optionRow: { input: FormControl, state: FormControl }): void {
    let foundKey = null;
    this.displayRows.forEach((row, key) => {
      if (row === optionRow) {
        foundKey = key;
      }
    });

    if (!_.isNull(foundKey)) {
      this.displayRows[foundKey] = {
        input: new FormControl(this.displayRows[foundKey].input.value),
        state: new FormControl(null)
      };
    }
  }

  addDisplayOption(): void {
    this.displayRows.push({
      input: new FormControl(null),
      state: new FormControl(null)
    });
  }

  closePopover(): void {
    this.popoverController.dismiss();
  }

  submitOptions(): void {
    const lightConfig = this.prepareLightConfig();

    this.prepareDisplayRows();

    if (this.displayRows.length === 0) {
      this.popoverController.dismiss();
    }

    if (this.displayRows.length !== Object.keys(lightConfig).length) {
      this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
        type: NotificationTypeEnum.Error,
        message: this.translate.instant("PAGE_DEVICE_CONFIGURATION.CONFIGURATION_INVALID_SAME_STATE")
      });
    }
    else {
      
      if (!lightConfig || !Object.keys(lightConfig).length) {
        this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
          type: NotificationTypeEnum.Warn,
          message: this.translate.instant("PAGE_DEVICE_CONFIGURATION.CONFIGURATION_INVALID")
        });
      }
  
      this.popoverController.dismiss({
        lightConfig: lightConfig
      })
    }
    
  }

  private prepareDisplayRows(): any {
    let tempArray = [];
    this.displayRows.forEach(element => {
      if (element.state.value !== null) {
        tempArray.push(element);
      }
    });
    this.displayRows = tempArray;
  }

  private prepareLightConfig(): any {
    let lightConfigMap = null;

    this.displayRows.forEach(row => {
      if (row.input.value && row.state.value) {
        if (!lightConfigMap) {
          lightConfigMap = {};
        }

        lightConfigMap[row.input.value] = row.state.value;
      }
    });

    return lightConfigMap;
  }

}
