import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QStateInputComponent } from "./qstate-input.component";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponentModule } from '../svg-icon/svg-icon.module';

@NgModule({
    imports:        [ CommonModule, FormsModule, IonicModule, SvgIconComponentModule ],
    declarations:   [ QStateInputComponent ],
    exports:        [ QStateInputComponent ]
})
export class QStateInputComponentModule {}