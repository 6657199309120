import { AfterContentInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'qstate-input',
  templateUrl: './qstate-input.component.html',
  styleUrls: ['./qstate-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QStateInputComponent),
      multi: true
    }
  ]
})
export class QStateInputComponent implements ControlValueAccessor, AfterContentInit {

  // custom class
  @Input() set customClass(value: string) {
    if (value) {
      this._customClass = value;
    }
  }
  get customClass(): string {
    return this._customClass;
  }

  // the input value
  @Input() set value(value: string) {
    this._value = value;
  }
  get value(): string {
    return this._value;
  }

  // readonly flag
  @Input() set readonly(value: boolean) {
    this._readonly = value;
  }
  get readonly(): boolean {
    return this._readonly;
  }

  // disabled flag
  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  // readonly flag
  @Input() set required(value: boolean) {
    this._required = value;
  }
  get required(): boolean {
    return this._required;
  }

  // type variable
  @Input() set type(value: string) {
    this._type = value;
  }
  get type(): string {
    return this._type;
  }

  // the input value
  @Input() set label(value: string) {
    this._label = value;
  }
  get label(): string {
    return this._label;
  }

  // the input value
  @Input() set icon(value: string) {
    this._icon = value;
  }
  get icon(): string {
    return this._icon;
  }

  @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>(null);

  // private vars
  private _customClass: string = "";
  private _value: string = "";
  private _required: boolean;
  private _readonly: boolean = false;
  private _disabled: boolean = false;
  private _type: string = "text";
  private _label: string;
  private _icon: string;

  onTouched = () => { };
  onChange = _ => { };

  @ViewChild('qstatefield', { static: true, read: ElementRef }) inputElementRef: ElementRef;

  constructor(private _renderer: Renderer2) { }

  ngAfterContentInit() {
    this.onChange(this.value);
  }

  onInputChange() {
    const value = this.inputElementRef.nativeElement.value;
    this.onChange(value);
  }

  onBlur() {
    this.onTouched();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: string) {
    this._renderer.setProperty(this.inputElementRef.nativeElement, 'value', value);
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this.inputElementRef.nativeElement, 'disabled', isDisabled);
  }

}
