import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { VisualizationConfigurationComponent } from "./visualization-configuration.component";
import { QStateInputComponentModule } from "../qstate-input/qstate-input.module";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponentModule } from "../svg-icon/svg-icon.module";
import { VisualizationSettingsPopoverComponentModule } from "../visualization-settings-popover/visualization-settings-popover.module";

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        QStateInputComponentModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        SvgIconComponentModule,
        VisualizationSettingsPopoverComponentModule
    ],
    declarations:   [ VisualizationConfigurationComponent ],
    exports:        [ VisualizationConfigurationComponent ]
})
export class VisualizationConfigurationComponentModule {}