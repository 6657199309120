import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ModalInterface } from 'src/app/interfaces/modal.interface';
import * as _ from 'lodash';
import { QStateStore } from 'src/app/store/qstate.main.store';
import { QStateStoreEnum } from 'src/app/store/qstate.enum.store';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { LicenseType } from 'src/app/enums/license-type.enum';

@Component({
  selector: 'default-configuration',
  templateUrl: './default-configuration.component.html',
  styleUrls: ['./default-configuration.component.scss'],
})
export class DefaultConfigurationComponent implements OnDestroy, ModalInterface {

  @Input() set connectedDevice(value: any) {
    this._connectedDevice = value;
    this.setForm();
  }
  get connectedDevice(): any {
    return this._connectedDevice;
  }
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  subscriptions: Subscription[] = [];

  defaultDeviceForm: FormGroup;
  private _connectedDevice: any;

  LicenseType = LicenseType;

  constructor(private router: Router, private modalController: ModalController, private store: QStateStore, private bluetoothService: BluetoothService) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onCancelForm(): void {
    this.modalController.dismiss({
      'dismissed': true
    });

    this.modalController = null;
    
    this.bluetoothService.disconnectFromDeviceWithId(this.connectedDevice.bleUUID);
    this.router.navigate(['/home']);
    // this.store.dispatch(QStateStoreEnum.UPDATE_GUI, { loadDevices: true });
  }
  
  onCreateForm(): void {
    this.onSubmit.emit(this.defaultDeviceForm.value);
  }

  private setForm(): void {
    this.defaultDeviceForm = new FormGroup({
      deviceId: new FormControl((this.connectedDevice as any).id),
      bleUUID: new FormControl((this.connectedDevice as any).bleUUID),
      name: new FormControl(_.get(this.connectedDevice, 'existingDevice.name', ''), [Validators.required]),
      manufacturer: new FormControl(_.get(this.connectedDevice, 'existingDevice.manufacturer', ''), [Validators.required]),
      typeDesignation: new FormControl(_.get(this.connectedDevice, 'existingDevice.typeDesignation', '')),
      constructionYear: new FormControl(_.get(this.connectedDevice, 'existingDevice.constructionYear', ''))
    });
  }

}
