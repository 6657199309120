export const environment = {
    production: true,
    firebase: {
      apiKey: "AIzaSyBSYALVBrDcpyEVAmCj00TYlsZndkzx9_8",
      authDomain: "iot-app-development-303110.firebaseapp.com",
      projectId: "iot-app-development-303110",
      storageBucket: "iot-app-development-303110.appspot.com",
      messagingSenderId: "144837450637",
      appId: "1:144837450637:web:19faffb7b3becf2d5f3bc4",
      measurementId: "G-623QLCHX5C"
    }
  };
  
  export const globals = {
    apiEndpoint: 'https://api.stage.qstate.io',
    appCountries: ['at', 'de']
  }
  
  export const lightColors = {
    "RED": "#FF4636",
    "YELLOW": "#FFC409",
    "GREEN": "#2DD36F",
    "BLUE": "#0000FF",
    "WHITE": "#FFFFFF"
  }