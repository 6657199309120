import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BLE } from '@ionic-native/ble/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { QStateStore } from './store/qstate.main.store';
import { AppErrorHandlerHelper } from './helpers/error-handler.helper';
import { IonIntlTelInputModule } from 'ion-intl-tel-input';
import { SvgIconComponentModule } from './components/svg-icon/svg-icon.module';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { QStateTranslateLoader } from './services/translation-loader.service';
import { ConfigurationModalPageModule } from './modals/configuration/configuration.modal.module';
import { WorkspaceModalModule } from './modals/workspace/workspace.modal.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LightStateModalModule } from './modals/light-state/light-state.modal.module';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/angular";
import { HealHealthCheckModalModule } from './modals/health-check/health-check.modal.module';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

Sentry.init({
  dsn: "https://559ccfaa83d6415aa50dff2be0caa849@o990766.ingest.sentry.io/5947507"
});

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    ReactiveFormsModule,
    IonIntlTelInputModule,
    SvgIconComponentModule,
    ConfigurationModalPageModule,
    WorkspaceModalModule,
    HealHealthCheckModalModule,
    LightStateModalModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useClass: QStateTranslateLoader,
      }
    }),
    HttpClientModule
  ],
  providers: [
    BLE, 
    AngularFireModule,
    QStateStore,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    QRScanner,
    Vibration,
    AndroidPermissions,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandlerHelper },
    Diagnostic
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
