export enum QStateStoreEnum {
    LOADING = "loading",
    NOTIFICATIONS = "notifications",
    ALERTS = "alerts",
    UPDATE_GUI = "update_gui",
    UPDATE_NOTIFICATIONS = "update_notifications",
    USER_PROFILE = "user",
    DATA = "data",
    SELECTED_WORKSPACE = "selected_workspace",
    CURRENT_DEVICE_ADD_STEP = "device_add_step",
    GLOBAL_NOTIFICATIONS = "global_notifications",
    CURRENT_DEVICE_CONNECTED_BLEUUID = "current_device_connected_bleuuid"
}