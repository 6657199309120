import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';
import { Subscription, timer } from 'rxjs';
import { DeviceApiService } from 'src/app/services/device-api.service';
import { QStateStoreEnum } from 'src/app/store/qstate.enum.store';
import { lightColors } from 'src/environments/environment';


@Component({
  selector: 'light-state-modal',
  templateUrl: './light-state.modal.html',
  styleUrls: ['./light-state.modal.scss']
})
export class LightStateModal implements OnInit {

  lightStates: any[];
  device: any;
  lightPollingIntervall: number = 3000;
  pollingSubscription: Subscription;

  constructor(public navParams: NavParams, private modalController: ModalController, private deviceApiService: DeviceApiService) { }

  ngOnInit() {
  
    this.unsubscribePolling();

    this.device = _.get(this.navParams, 'data.device', null);

    this.pollingSubscription = timer(0, this.lightPollingIntervall).subscribe(() => {
      this.loadDevice();
    });

    this.mapLightState();
  }

  ngOnDestroy() {
    this.unsubscribePolling();
  }

  private mapLightState(): void {
    this.lightStates = _.get(this.device, 'lightState', null);
    
    if (this.lightStates) {
      this.lightStates = this.lightStates.sort((stateA, stateB) => {
        return stateA.order - stateB.order;
      });
    }
  }

  private unsubscribePolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  getSignalColor(colorIdentifier: string): string {
    return lightColors[colorIdentifier];
  }

  closeLightModal(): void {
    this.modalController.dismiss();
  }

  private loadDevice(): void {
    const selectedWorkspace = localStorage.getItem(QStateStoreEnum.SELECTED_WORKSPACE);

    this.deviceApiService.getDevice(selectedWorkspace, this.device.deviceId).subscribe(device => {
      if (!_.get(device, 'error')) {
        this.device = device;
        this.mapLightState();
      }
    });
  }


}
