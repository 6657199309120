import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SvgIconComponent } from "./svg-icon.component";
import { IonicModule } from "@ionic/angular";

@NgModule({
    imports:        [ CommonModule, IonicModule ],
    declarations:   [ SvgIconComponent ],
    exports:        [ SvgIconComponent ]
})
export class SvgIconComponentModule {}