import { Injectable } from '@angular/core';
import { LocalStorageEnum } from '../enums/local-storage.enum';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AngularFireAuth } from '@angular/fire/auth';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { QStateStoreEnum } from '../store/qstate.enum.store';
import { HttpRequest } from '@angular/common/http';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private firebaseAuthentication: AngularFireAuth,
    private router: Router
  ) { }

  isTokenExpired(): boolean {
    let decodedToken = helper.decodeToken(localStorage.getItem(LocalStorageEnum.AccessToken));
    if (!decodedToken || !_.get(decodedToken, 'exp')) {
      return false;
    }
    const date = (new Date().getTime() / 1000) | 0;
    return (date.valueOf() > decodedToken.exp.valueOf());
  }

  getNewToken(httpRequest?: HttpRequest<any>) {
    return new Promise((resolve) => {
        this.firebaseAuthentication.onAuthStateChanged(user => {
            if (!_.isNull(user)) {
              user.getIdToken(true).then(token => {
                this.storeTokeInLocalStorage(token);
                resolve(true);
              });
            } else {
              // notification service exception
              if (_.get(httpRequest, 'url', '').indexOf("alerts/notifications") === -1) {
                this.router.navigate(['login']);
              }
            }
        });
    }); 
  }

  logout() {
    return new Promise((resolve) => {
      this.firebaseAuthentication.signOut();
      
      localStorage.removeItem(LocalStorageEnum.AccessToken);
      localStorage.removeItem(QStateStoreEnum.USER_PROFILE);
      localStorage.removeItem(QStateStoreEnum.SELECTED_WORKSPACE);

      resolve(true);
    });  
  }

  getExistingToken() {
    return localStorage.getItem(LocalStorageEnum.AccessToken);
  }

  storeTokeInLocalStorage(token) {
    localStorage.setItem(LocalStorageEnum.AccessToken, token);
  }


  /*
  * Expired Token Test:

    eyJhbGciOiJSUzI1NiIsImtpZCI6IjRlMDBlOGZlNWYyYzg4Y2YwYzcwNDRmMzA3ZjdlNzM5Nzg4ZTRmMWUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vaW90LWFwcC1kZXZlbG9wbWVudC0zMDMxMTAiLCJhdWQiOiJpb3QtYXBwLWRldmVsb3BtZW50LTMwMzExMCIsImF1dGhfdGltZSI6MTYxNjQ5MDkwNSwidXNlcl9pZCI6IjdQaWtHUE1LMTFQMjl5Y1JTRVBDR3JtMFhpYTIiLCJzdWIiOiI3UGlrR1BNSzExUDI5eWNSU0VQQ0dybTBYaWEyIiwiaWF0IjoxNjE2NDkwOTA1LCJleHAiOjE2MTY0OTQ1MDUsImVtYWlsIjoiaGFubmVzd2lsaGVsbWVyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImhhbm5lc3dpbGhlbG1lckBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.ROT_99JnZbIMNkTNFqj3oqpCfdut3JKN4nv-IGUesAzWwjlKuCDICSkhbASR5hMqjAnsCYDas9VCrPn9nHrya21upV_AhvMbfSnW9r617BsJVLSJ-RvjpWJ5qGCs_0bgZzkbc_ajVG3GJYQ1BA9J3h8BWLAlICgIm8Mr6enBibyCu8SoUnDQ6ez9NcqtNN0GNsXHGkZw2logAUffmZiSycBrDWc3bcqWcaY-W4iAPkLzXRYFHmlI9GF15LMTFIz0lfiOA3yQO7surRJV7E3BW54a_jXrZFfTSLjJI4M2RS5cZ_gd3UtEL6A_Jx2bDIuTtoiwo_leHfRKsakoDVYQrA
   
  */
}
