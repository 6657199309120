import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NetworkConfigurationComponentModule } from 'src/app/components/network-configuration/network-configuration.module';
import { LightStateModal } from './light-state.modal';
import { SvgIconComponentModule } from 'src/app/components/svg-icon/svg-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { WorkspaceComponentModule } from 'src/app/components/workspace/workspace.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NetworkConfigurationComponentModule,
    SvgIconComponentModule,
    TranslateModule,
    WorkspaceComponentModule
  ],
  declarations: [LightStateModal],
  exports: [LightStateModal]
})
export class LightStateModalModule {}
