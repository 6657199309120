import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VisualizationSettingsPopoverComponent } from "./visualization-settings-popover.component";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponentModule } from '../svg-icon/svg-icon.module';
import { TranslateModule } from "@ngx-translate/core";
import { QStateSelectComponentModule } from "../qstate-select/qstate-select.module";

@NgModule({
    imports:        [ CommonModule, FormsModule, ReactiveFormsModule, IonicModule, SvgIconComponentModule, TranslateModule, QStateSelectComponentModule ],
    declarations:   [ VisualizationSettingsPopoverComponent ],
    exports:        [ VisualizationSettingsPopoverComponent ]
})
export class VisualizationSettingsPopoverComponentModule {}