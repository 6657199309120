import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WorkspaceComponent } from "./workspace.component";
import { IonicModule } from "@ionic/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponentModule } from '../svg-icon/svg-icon.module';
import { QuickActionBoxComponentModule } from "../quick-action-box/quick-action-box.module";
import { TranslateModule } from "@ngx-translate/core";
import { QStateInputComponentModule } from "../qstate-input/qstate-input.module";

@NgModule({
    imports: [ 
        CommonModule, 
        IonicModule, 
        FormsModule, 
        ReactiveFormsModule,
        SvgIconComponentModule,
        TranslateModule,
        QStateInputComponentModule
    ],
    declarations: [ WorkspaceComponent ],
    exports:[ WorkspaceComponent ]
})
export class WorkspaceComponentModule {}