import { Injectable } from "@angular/core";
import * as _ from 'lodash';
import { BehaviorSubject } from "rxjs";
import { QStateStoreEnum } from "./qstate.enum.store";
import { QStateStoreAction } from "./qstate.action.store";
import { environment } from "src/environments/environment";

@Injectable()
export class QStateStore {

    private _store: QStateStoreEnum[] = [];
    private _watchers: Array<BehaviorSubject<any>> = [];

    /**
     * public setters and getters for private members
     */
    get store(): QStateStoreEnum[] {
        return this._store;
    }
    get watchers(): Array<BehaviorSubject<any>> {
        return this._watchers;
    }
    

    // logging config
    private loggingEnabled = {
        default: !environment.production ? true : false,
        select: false,
        dispatch: !environment.production ? true : false,
        delete: !environment.production ? true : false
    };

    /**
     * method to watch an localstorage entry for changes
     * @param entry 
     */
    select(entry: QStateStoreEnum): BehaviorSubject<any> {
        const obs = new BehaviorSubject(this.hasProperty(entry) ? this.getProperty(entry) : null);
        this.watchers[entry] = obs;
        this.addToWatchers(entry, obs);

        // logging
        this.logAction(QStateStoreAction.SELECT, entry, obs.getValue());

        return obs;
    }

    /**
     * method to remove an Observable to the watcher array
     * @param entry 
     */
    delete(entry: QStateStoreEnum): void {
        _.unset(this._store, entry);
        _.unset(this.watchers, entry);

        // logging
        this.logAction(QStateStoreAction.DELETE, entry);
    }

    /**
     * method to update a watcher entry
     * @param entry 
     * @param value
     */
    dispatch(entry: QStateStoreEnum, value: any, toLocalStorage: boolean = false): void {
        // add a watcher if on dispatch is no watcher detected
        if (!this.watchers[entry]) {
            this.select(entry);
        }

        this._store[entry] = value;
        const watcherEntry$: BehaviorSubject<any> = this.watchers[entry];
        watcherEntry$.next(value);

        if (toLocalStorage) {
            if (_.isObject(value) || _.isArray(value)) {
                localStorage.setItem(entry, JSON.stringify(value));
            }
            else {
                localStorage.setItem(entry, value);
            }
        }

        // logging
        this.logAction(QStateStoreAction.DISPATCH, entry, value);
    }

    /**
     * retrieves a certain value of the store
     * @param entry 
     */
    getValue(entry: QStateStoreEnum): any {
        if (this._store[entry]) {
            return this._store[entry];
        }

        return null;
    }
    
    /**
     * retrieves a certain value of the store with a filter option
     * @param entry 
     * @param filter `i.e. { key: value }`
     */
    getValueBy(entry: QStateStoreEnum, filter: any): any {
        const content = this.getValue(entry);
        if (content) {
            return _.find(content, filter);
        }

        return null;
    }

    /**
     * retrieves a certain value of the store with a filter option
     * @param entry 
     * @param filter `i.e. { key: value }`
     */
    getValuesBy(entry: QStateStoreEnum, filter: any): any[] {
        const content = this.getValue(entry);
        if (content) {
            return _.filter(content, filter);
        }

        return null;
    }

    /**
     * retrieves the current snapshot of the store object
     */
    getStoreValue(): any[] {
        return this._store;
    }

    private getItem(entry: QStateStoreEnum): any {
        return this._store[entry];
    }

    /**
     * method to get an app local storage entry
     * @param {string} entry 
     */
    private getProperty(entry: QStateStoreEnum): any {
        return this.getItem(entry);
    }

    /**
     * method to check if a certain entry exists
     * @return {boolean}
     */
    private hasProperty(entry: QStateStoreEnum): boolean {
        const item = this.getItem(entry);
        if (!_.isUndefined(item) && !_.isNull(item)) {
            return true;
        }
        return false;
    }

    /**
     * method to add an Observable to the watcher array
     * @param entry 
     */
    private addToWatchers(entry: QStateStoreEnum, obs: BehaviorSubject<any>): void {
        this.watchers[entry] = obs;
    }

    /**
     * method to log an action
     * @param action 
     * @param entry 
     * @param value 
     */
    private logAction(action: QStateStoreAction, entry: QStateStoreEnum, value: any = null): void {
        if (this.loggingEnabled.default) {
            // check if logging is enabled for certain actions
            if (this.loggingEnabled[action.toLowerCase()]) {
                const actionLabel = `${action}_${entry.toUpperCase()}`;
        
                // default js logging
                console.log(
                    `%c${actionLabel}`,
                    'color: green',
                    value,
                    this.getStoreValue(),
                );
            }
        }
    }

}