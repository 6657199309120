import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import * as _ from 'lodash';


@Component({
  selector: 'app-workspace-modal',
  templateUrl: './workspace.modal.html',
  styleUrls: ['./workspace.modal.scss']
})
export class WorkspaceModal {

  existingWorkspace: any;
  join: boolean;

  constructor(public navParams: NavParams) { }

  ngOnInit(): void {
    this.existingWorkspace = _.get(this.navParams, 'data.existingWorkspace', null);
    this.join = _.get(this.navParams, 'data.join', false);
  }

}
