import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
  },
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'device-configuration',
    loadChildren: () => import('./pages/device-configuration/device-configuration.module').then( m => m.DeviceConfigurationPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToHome }
  },
  {
    path: 'workspace-qr',
    loadChildren: () => import('./pages/workspace-qr/workspace-qr.module').then( m => m.WorkspaceQrPageModule)
  },
  {
    path: 'workspace-qr-scan',
    loadChildren: () => import('./pages/workspace-qr-scan/workspace-qr-scan.module').then( m => m.WorkspaceQrScanPageModule)
  },
  {
    path: 'license-qr-scan',
    loadChildren: () => import('./pages/license-qr-scan/license-qr-scan.module').then( m => m.LicenseQrScanPageModule)
  },
  {
    path: 'system',
    loadChildren: () => import('./pages/system/system.module').then( m => m.SystemPageModule)
  },
  {
    path: 'license',
    loadChildren: () => import('./pages/license/license.module').then( m => m.LicensePageModule)
  },
  {
    path: 'device-detail',
    loadChildren: () => import('./pages/device-detail/device-detail.module').then( m => m.DeviceDetailPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
