import { Component, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { globals } from 'src/environments/environment';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html'
})
export class SvgIconComponent implements OnDestroy {

  @Input() set svgSrc(value: string) {
    if (value) {
      this._svgSrc = value;
      this.loadSvgContent();
    }
  }
  get svgSrc(): string {
    return this._svgSrc;
  }

  @Input() set customClass(value: string) {
    if (value) {
      this._customClass = value;
    }
    else {
      this._customClass = "main";
    }
  }
  get customClass(): string {
    return this._customClass;
  }

  // contents
  private _svgSrc: string;
  private _customClass: string = "main";
  svgContent: any;
  subscriptions: Subscription[] = [];

  @ViewChild("svg") svgContainer: ElementRef;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * method to load the content
   */
  private loadSvgContent(): void {
    const parser = new DOMParser();

    const _this = this;
    const ajax = new XMLHttpRequest();
    ajax.open("GET", `/assets/media/${this.svgSrc}.svg`, true);
    ajax.send();
    ajax.onload = function (e) {
      const svgContent = ajax.responseText;
      const doc = parser.parseFromString(svgContent, "image/svg+xml");
      doc.querySelectorAll("path").forEach(elem => {
        elem.setAttribute("fill", "red");
      });
      doc.documentElement.setAttribute("fill", "red");

      const content = _this.sanitizer.bypassSecurityTrustHtml(svgContent);
      _this.svgContent = content;
    }
  }

}
