import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import * as _ from 'lodash';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { QStateStoreEnum } from 'src/app/store/qstate.enum.store';
import { QStateStore } from 'src/app/store/qstate.main.store';


@Component({
  selector: 'health-check-modal',
  templateUrl: './health-check.modal.html',
  styleUrls: ['./health-check.modal.scss']
})
export class HealthCheckModal {

  data: { bluetoothEnabled: boolean, locationApproved: boolean };

  constructor(
    public navParams: NavParams,
    private modalController: ModalController,
    private bluetoothService: BluetoothService,
    private store: QStateStore,
    private router: Router,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.data = _.get(this.navParams, 'data', null);
  }

  closeHealthCheckModal(): void {
    this.modalController.dismiss();
  }

  recallHealthCheck(): void {
    this.bluetoothService.registerHealthCheck();
    let stateRetry = 0;

    this.store.dispatch(QStateStoreEnum.LOADING, true);
    this.bluetoothService.healthState.subscribe(state => {
      if (state) {
        // set new properties
        this.data.bluetoothEnabled = state.bluetooth === "powered_on" ? true : false
        this.data.locationApproved = state.location;

        if (stateRetry < 4) {
          if (state.bluetooth === "powered_on" && state.location && stateRetry > 1) {
            stateRetry = 0;

            this.bluetoothService.unRegisterHealthCheck();
            this.store.dispatch(QStateStoreEnum.LOADING, false);
            this.modalController.dismiss();
            this.router.navigate(['/device-configuration']);
          }

          stateRetry++;
        }
        else {
          stateRetry = 0;
          this.bluetoothService.unRegisterHealthCheck();
          this.store.dispatch(QStateStoreEnum.LOADING, false);
        }
      }
    });
  }

}
