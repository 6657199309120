import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { BleCharacteristicEnum } from 'src/app/enums/ble-characteristic.enum';
import { BleServiceEnum } from 'src/app/enums/ble-service.enum';
import { NetworkStatusEnum } from 'src/app/enums/network-status.enum';
import * as _ from 'lodash';
import { ConfigurationWizzardEnum } from 'src/app/enums/configuration-wizzard.enum';
import { ModalController, Platform } from '@ionic/angular';
import { QStateStore } from 'src/app/store/qstate.main.store';
import { QStateStoreEnum } from 'src/app/store/qstate.enum.store';
import { DeviceApiService } from 'src/app/services/device-api.service';
import { DeviceProgressStateEnum } from 'src/app/enums/device-progress-state.enum';
import { NotificationTypeEnum } from 'src/app/enums/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'network-configuration',
  templateUrl: './network-configuration.component.html',
  styleUrls: ['./network-configuration.component.scss'],
})
export class NetworkConfigurationComponent implements OnInit {

  @Input() connectedDevice: any;
  @Input() skipNetwork: boolean;
  @Input() set createdApiDevice(value: any) {
    this._createdApiDevice = value;
  }
  get createdApiDevice(): any {
    return this._createdApiDevice;
  }

  @Output() onStep: EventEmitter<ConfigurationWizzardEnum> = new EventEmitter<ConfigurationWizzardEnum>(null);

  subscriptions: Subscription[] = [];
  selectedConnectionType: 'wifi' | 'mobile' = 'mobile';
  wifiStatus:string = "0";
  cellularStatus:number = 0;
  wifiSettingForm: FormGroup;
  ssIds:Array<string>  = [];
  testmode: boolean = false; 
  showLoading: boolean = true;

  // api device with mqtt data
  private _createdApiDevice: any;

  constructor(private bluetoothService:BluetoothService, 
              private cdRef:ChangeDetectorRef,
              public platform: Platform,
              private translate: TranslateService,
              public store: QStateStore,
              public router: Router,
              private modalController: ModalController,
              private deviceApiService: DeviceApiService) { 

    this.wifiSettingForm = new FormGroup({
      ssid: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
    })
  }

  ngOnInit() {
    if (!this.skipNetwork) {
      this.networkPreCheck();
      this.getSsIds();
    }
  }

  networkPreCheck() {
    this.showLoading = true;
    if (this.platform.is('android') || this.platform.is('ios') && (this.platform.is('cordova') || this.platform.is('capacitor'))) {
      this.testmode = false;
      if (_.get(this.connectedDevice, 'existingDevice.bleUUID')) {
        this.connectedDevice.bleUUID = this.connectedDevice.existingDevice.bleUUID;
      } 
      let interval = setInterval(() => {
        this.readCellularConnectionStatusCharacteristics();
        if (this.cellularStatus === 2) { 
          clearInterval(interval);
          this.showLoading = false;
        }
      }, 1000);
      setTimeout(()=>{ 
        clearInterval(interval);
        this.showLoading = false;
      }, 5000);
    }
    else {
      let interval = setInterval(() => {
        console.log("check dummy");
      }, 1000);
      setTimeout(()=>{ 
        clearInterval(interval);
        this.showLoading = false;
      }, 5000);
      this.testmode = true; 
      this.cellularStatus = 2;
    }
  }

  segmentChanged(event) {
    this.selectedConnectionType = event.detail.value;
    if (this.selectedConnectionType === "mobile") {
      this.networkPreCheck();
    }
    this.cdRef.detectChanges();
  }

  getSsIds() {
    if (this.connectedDevice) {
      _.get(this.connectedDevice, 'characteristics', []).forEach(characteristic => {
          if (this.checkIfWifiCharacteristic(characteristic.characteristic.toUpperCase())) {
            let bluetoothReadServiceSubscription = this.bluetoothService.readDeviceCharacteristic(this.connectedDevice.bleUUID, characteristic.service, characteristic.characteristic).subscribe(
              buffer => {
                let ssId = this.bluetoothService.convertBytesToString(buffer);
                let cleanedSsId = ssId.split("\u0000").join("");
                if (cleanedSsId != "") {
                  this.ssIds.push(ssId);
                }
              }
            );
            this.subscriptions.push(bluetoothReadServiceSubscription);
          }
      });
    }
  }

  checkIfWifiCharacteristic(characteristic) {
    if (  characteristic === BleCharacteristicEnum.WifiSsid0.toUpperCase() ||
          characteristic === BleCharacteristicEnum.WifiSsid1.toUpperCase() ||
          characteristic === BleCharacteristicEnum.WifiSsid2.toUpperCase() ||
          characteristic === BleCharacteristicEnum.WifiSsid3.toUpperCase() ||
          characteristic === BleCharacteristicEnum.WifiSsid4.toUpperCase() ||
          characteristic === BleCharacteristicEnum.WifiSsid5.toUpperCase() ||
          characteristic === BleCharacteristicEnum.WifiSsid6.toUpperCase() ||
          characteristic === BleCharacteristicEnum.WifiSsid7.toUpperCase() ||
          characteristic === BleCharacteristicEnum.WifiSsid8.toUpperCase() ||
          characteristic === BleCharacteristicEnum.WifiSsid9.toUpperCase() ) {
      return true;
    } else {
      return false; 
    }
  }

  submitWifiSettings() {
    if (this.platform.is('android') || this.platform.is('ios') && (this.platform.is('cordova') || this.platform.is('capacitor'))) {
      if (this.wifiSettingForm.get("password").value === "") {
        const notification = { message: this.translate.instant("ERROR_MESSAGES.PASSWORD_EMPTY"), type: NotificationTypeEnum.Error };
        this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, notification);
      } else if (this.wifiSettingForm.get("ssid").value.replace(/\0[\s\S]*$/g,'') === "") {
        const notification = { message: this.translate.instant("ERROR_MESSAGES.SSID_EMPTY"), type: NotificationTypeEnum.Error };
        this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, notification);
      } else {
        this.store.dispatch(QStateStoreEnum.LOADING, true);
        this.writeWifiSsid(this.wifiSettingForm.get("ssid").value.replace(/\0[\s\S]*$/g,''));
        this.writeWifiPassword(this.wifiSettingForm.get("password").value);
        this.startWlanCheck();
      }
    } else {
      this.onStep.emit(ConfigurationWizzardEnum.Visualization);
      this.updateDeviceConfigState();
    }
  }

  writeWifiSsid(ssid) { 
    let bluetoothWriteWifiSsidSubscription = this.bluetoothService.writeDeviceCharacteristic(ssid, this.connectedDevice.bleUUID, BleServiceEnum.WifiService, BleCharacteristicEnum.ConnectionWifiSsid).subscribe(
      () => console.log("ssid success"),
      e => console.log('Unexpected Error', 'Error updating characteristic ' + e)
    )
    this.subscriptions.push(bluetoothWriteWifiSsidSubscription);
  } 
  
  writeWifiPassword(password) { 
    let bluetoothWriteWifiPasswordSubscription = this.bluetoothService.writeDeviceCharacteristic(password, this.connectedDevice.bleUUID, BleServiceEnum.WifiService, BleCharacteristicEnum.ConnectionWifiPassword).subscribe(
      () => console.log("password success"),
      e => console.log('Unexpected Error', 'Error updating characteristic ' + e)
    )
    this.subscriptions.push(bluetoothWriteWifiPasswordSubscription);
  }

  checkCellularConnectionStatus() {  
    if (this.platform.is('android') || this.platform.is('ios') && (this.platform.is('cordova') || this.platform.is('capacitor'))) {
      this.store.dispatch(QStateStoreEnum.LOADING, true);
      this.readCellularConnectionStatusCharacteristics();
      setTimeout(()=>{ 
        if (this.cellularStatus === 2) { 
          this.writeMqttConnectionData();
          setTimeout(()=>{ 
            this.store.dispatch(QStateStoreEnum.LOADING, false);
            this.onStep.emit(ConfigurationWizzardEnum.Visualization);
            this.updateDeviceConfigState();
          }, 3000);
        } else {
          const notification = { message: this.translate.instant("ERROR_MESSAGES.NO_CELLULAR_CONNECTION"), type: NotificationTypeEnum.Error };
          this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, notification);
        }
      }, 2000);
    } else {
      this.onStep.emit(ConfigurationWizzardEnum.Visualization);
      this.updateDeviceConfigState();
    }
  }

  readCellularConnectionStatusCharacteristics() {
    let bluetoothReadCellularStatusSubscription = this.bluetoothService.readDeviceCharacteristic(this.connectedDevice.bleUUID, BleServiceEnum.CellularService, BleCharacteristicEnum.CellularStatus ).subscribe(
      (buffer) => {
        this.cellularStatus = this.bluetoothService.convertByteCellularStatus(buffer);
        console.log("Cellular State: "+this.cellularStatus);
      },
      e => {
        if (_.get(this.connectedDevice, 'existingDevice.bleUUID')) {
          this.modalController.dismiss({
            rescan: true
          });
        } 
      } 
    );
    this.subscriptions.push(bluetoothReadCellularStatusSubscription);
  }

  startWlanCheck() {
    let interval = setInterval(() => {
      this.readWlanNetworkConnectionStatusCharacteristics();
      if (this.wifiStatus === NetworkStatusEnum[3]) {
        this.store.dispatch(QStateStoreEnum.LOADING, false);
        clearInterval(interval);
        this.writeMqttConnectionData();
        this.onStep.emit(ConfigurationWizzardEnum.Visualization);
        this.updateDeviceConfigState();
      }
    }, 1000);
    setTimeout(()=>{ 
      clearInterval(interval);
      if (this.wifiStatus != NetworkStatusEnum[3]) {
        this.store.dispatch(QStateStoreEnum.LOADING, false);
        const notification = { message: this.translate.instant("ERROR_MESSAGES.NO_WIFI_CONNECTION"), type: NotificationTypeEnum.Error };
        this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, notification);
      }
    }, 5000);
  }

  readWlanNetworkConnectionStatusCharacteristics() {
    let bluetoothReadWlanStatusSubscription = this.bluetoothService.readDeviceCharacteristic(this.connectedDevice.bleUUID, BleServiceEnum.WifiService, BleCharacteristicEnum.ConnectionWifiStatus).subscribe(
      (buffer) => {
        this.wifiStatus = this.bluetoothService.convertByteToNetworkStatusEnum(buffer);
      },
      e => {
        const notification = { message: this.translate.instant(e), type: NotificationTypeEnum.Error };
        this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, notification);
      } 
    );
    this.subscriptions.push(bluetoothReadWlanStatusSubscription);
  }

  writeMqttConnectionData() {
    console.log("MQTT-Data:");
    console.log(this.createdApiDevice);
    if (_.get(this.createdApiDevice, 'gcpProjectId') && _.get(this.createdApiDevice, 'privateKey')) {
      console.log(this.connectedDevice.bleUUID);
      this.writeMqttProjectId( this.createdApiDevice.gcpProjectId);
      this.writeMqttLocation( this.createdApiDevice.gcpLocation);
      this.writeMqttRegistryId( this.createdApiDevice.gcpRegistryId);
      this.writeMqttDeviceId( this.createdApiDevice.mqttDeviceId);
      this.writeMqttPrivateKey( this.createdApiDevice.privateKey);
    } else {
      const notification = { message: this.translate.instant("ERROR_MESSAGES.MQTT_DATA_ERROR"), type: NotificationTypeEnum.Error };
      this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, notification);
    }
  }

  writeMqttProjectId(projectId) {
    console.log("Write Project ID: "+projectId+" to device "+this.connectedDevice.bleUUID);
    console.log("MQTT ServiceID: " +BleServiceEnum.MqttService );
    console.log("MQTT CharacteristicID: " + BleCharacteristicEnum.MqttProjectId);
    let bluetoothWriteProjectIdSubscription = this.bluetoothService.writeDeviceCharacteristic(projectId, this.connectedDevice.bleUUID, BleServiceEnum.MqttService, BleCharacteristicEnum.MqttProjectId).subscribe(
      () => console.log("projectId success"),
      e => console.log('Unexpected Error', 'Error updating characteristic ' + e)
    )
    this.subscriptions.push(bluetoothWriteProjectIdSubscription);
  }

  writeMqttLocation(location) {
    console.log("Write Location: "+location);
    console.log("MQTT ServiceID: " +BleServiceEnum.MqttService );
    console.log("MQTT CharacteristicID: " + BleCharacteristicEnum.MqttLocation);
    let bluetoothWriteLocationSubscription = this.bluetoothService.writeDeviceCharacteristic(location, this.connectedDevice.bleUUID, BleServiceEnum.MqttService, BleCharacteristicEnum.MqttLocation).subscribe(
      () => console.log("location success"),
      e => console.log('Unexpected Error', 'Error updating characteristic ' + e)
    )
    this.subscriptions.push(bluetoothWriteLocationSubscription);
  }

  writeMqttRegistryId(registryId) {
    console.log("Write Registry ID: "+registryId);
    console.log("MQTT ServiceID: " +BleServiceEnum.MqttService );
    console.log("MQTT CharacteristicID: " + BleCharacteristicEnum.MqttRegistryId);
    let bluetoothWriteRegistryIdSubscription = this.bluetoothService.writeDeviceCharacteristic(registryId, this.connectedDevice.bleUUID, BleServiceEnum.MqttService, BleCharacteristicEnum.MqttRegistryId).subscribe(
      () => console.log("registryId success"),
      e => console.log('Unexpected Error', 'Error updating characteristic ' + e)
    )
    this.subscriptions.push(bluetoothWriteRegistryIdSubscription);
  }

  writeMqttDeviceId(deviceId) {
    console.log("Write Device ID: "+deviceId);
    console.log("MQTT ServiceID: " +BleServiceEnum.MqttService );
    console.log("MQTT CharacteristicID: " + BleCharacteristicEnum.MqttDeviceId);
    let bluetoothWriteDeviceIdSubscription = this.bluetoothService.writeDeviceCharacteristic(deviceId, this.connectedDevice.bleUUID, BleServiceEnum.MqttService, BleCharacteristicEnum.MqttDeviceId).subscribe(
      () => console.log("deviceId success"),
      e => console.log('Unexpected Error', 'Error updating characteristic ' + e)
    )
    this.subscriptions.push(bluetoothWriteDeviceIdSubscription);
  }

  writeMqttPrivateKey(privateKey) {
    console.log("Write Private Key: " + privateKey);
    console.log("MQTT ServiceID: " +BleServiceEnum.MqttService );
    console.log("MQTT CharacteristicID: " + BleCharacteristicEnum.MqttPrivateKey);
    let bluetoothWritePrivateKeySubscription = this.bluetoothService.writeDeviceCharacteristic(privateKey, this.connectedDevice.bleUUID, BleServiceEnum.MqttService, BleCharacteristicEnum.MqttPrivateKey).subscribe(
      () => console.log("privatekey success"),
      e => console.log('Unexpected Error', 'Error updating characteristic ' + e)
    )
    this.subscriptions.push(bluetoothWritePrivateKeySubscription);
  }

  private updateDeviceConfigState(): void {
    const selectedWorkspace = localStorage.getItem(QStateStoreEnum.SELECTED_WORKSPACE);
    if (!_.get(this.connectedDevice, 'existingDevice.configurationProgress') || _.get(this.connectedDevice, 'existingDevice.configurationProgress') === DeviceProgressStateEnum.Created) {
      this.deviceApiService.setDeviceConfigureProgressState(selectedWorkspace, this.connectedDevice.id, DeviceProgressStateEnum.Connected).subscribe(state => {
      });
    }
  }

  stepBack(): void {
    this.onStep.emit(ConfigurationWizzardEnum.Default);
  }

  skipNetworkPage() {
    this.onStep.emit(ConfigurationWizzardEnum.Visualization);
    this.updateDeviceConfigState();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.bluetoothService.disconnectFromDeviceWithId(this.connectedDevice.bleUUID);
  }

}
