import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ConfigurationWizzardEnum } from 'src/app/enums/configuration-wizzard.enum';
import { DeviceApiService } from 'src/app/services/device-api.service';
import { QStateStore } from 'src/app/store/qstate.main.store';
import { QStateStoreEnum } from 'src/app/store/qstate.enum.store';
import { NotificationTypeEnum } from 'src/app/enums/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { LicenseType } from 'src/app/enums/license-type.enum';


@Component({
  selector: 'app-configuration-modal',
  templateUrl: './configuration.modal.html',
  styleUrls: ['./configuration.modal.scss']
})
export class ConfigurationModal implements OnInit {

  connectedDevice: any;
  subscriptions: Subscription[] = [];
  loaded: boolean = false;
  skipNetwork: boolean = false;
  currentStep: ConfigurationWizzardEnum;
  createdApiDevice: any;
  deviceLoaded: boolean = false;

  constructor(
    public modalController: ModalController,
    public navParams: NavParams,
    private deviceApiService: DeviceApiService,
    private store: QStateStore,
    private translate: TranslateService,
    private bluetoothService: BluetoothService,
    private alertController: AlertController,
    private router: Router) { }

  ngOnInit() {
    this.getDevice();
    this.connectedDevice = _.get(this.navParams, 'data.connectedDevice', null);
    this.skipNetwork = _.get(this.navParams, 'data.skipNetwork', false);
    const step = _.get(this.navParams, 'data.selectedStep', ConfigurationWizzardEnum.Default);
    this.setStep(step, true);
    //this.setStep(ConfigurationWizzardEnum.Network, true);
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  private onCancelForm(): void {
    this.modalController.dismiss({
      'dismissed': true
    });

    this.modalController = null;
    
    this.bluetoothService.disconnectFromDeviceWithId(this.connectedDevice.bleUUID);
    this.router.navigate(['/home']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  getDevice(): void {
    const selectedWorkspace = localStorage.getItem(QStateStoreEnum.SELECTED_WORKSPACE);

    this.store.dispatch(QStateStoreEnum.LOADING, true);

    if (_.get(this.connectedDevice, 'id')) {
      this.deviceApiService.getDeviceWithLightConfig(selectedWorkspace, _.get(this.connectedDevice, 'id')).subscribe(data => {

        if (!_.get(data, 'device.error')) {
          _.set(this.connectedDevice, 'existingDevice', data.device);

          this.connectedDevice = _.merge({}, this.connectedDevice, {
            existingDevice: data.device
          });
        }

        if (!_.get(data, 'lightConfig.error')) {
          _.set(this.connectedDevice, 'existingLightConfig', data.lightConfig);
        }

        if (!_.get(data, 'deviceConfiguration.error')) {
          // _.set(this.connectedDevice, 'deviceConfiguration', data.deviceConfiguration);
          this.createdApiDevice = data.deviceConfiguration;
        }

        if (!_.get(data, 'deviceLicense.error')) {
          this.connectedDevice = _.merge({}, this.connectedDevice, {
            deviceLicense: data.deviceLicense
          });
        }

        console.log("connectedDevice", this.connectedDevice);

        this.store.dispatch(QStateStoreEnum.LOADING, false);

        setTimeout(() => {
          this.deviceLoaded = true;
        }, 100);
      });
    }
  }

  submitDefaultData(formData: any): void {
    const selectedWorkspace = localStorage.getItem(QStateStoreEnum.SELECTED_WORKSPACE);

    // this.store.dispatch(QStateStoreEnum.LOADING, true);

    if (_.get(this.connectedDevice, 'existingDevice')) {
      this.updateDevice(formData, selectedWorkspace);
    }
    else {
      this.addDevice(formData, selectedWorkspace);
    }
  }

  submitLightConfig(data: any): void {
    const selectedWorkspace = localStorage.getItem(QStateStoreEnum.SELECTED_WORKSPACE);

    const lightData = {
      "lightConfig": data
    };

    this.store.dispatch(QStateStoreEnum.LOADING, true);

    this.deviceApiService.addLightConfigToDevice(selectedWorkspace, _.get(this.connectedDevice, 'id'), lightData).subscribe(lightConfig => {
      console.log("added light config", lightConfig);

      setTimeout(() => {
        this.store.dispatch(QStateStoreEnum.LOADING, false);
      }, 500);

      if (_.get(lightConfig, 'ok') == false) {
        this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
          type: NotificationTypeEnum.Error,
          message: _.get(lightConfig, 'message', 'Error')
        });
      }
      else {
        this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
          type: NotificationTypeEnum.Success,
          message: this.translate.instant("PAGE_DEVICE_CONFIGURATION.DEVICE_LIGHT_CONFIG_SAVED_SUCCESSFULLY")
        });

        this.modalController.dismiss();
        this.router.navigate(['/home']);

        // this.store.dispatch(QStateStoreEnum.UPDATE_GUI, { loadDevices: true });
      }

    });
  }

  private addDevice(formData: any, workspace: string, force: boolean = false): void {
    this.deviceApiService.addDevice(this.connectedDevice, workspace, formData, force).subscribe(device => {
      if (_.get(device, 'error')) {
        const detailError = _.get(device, 'error');

        if (_.get(detailError, 'key', '') === 'ALREADY_EXISTS_OTHER_WORKSPACE') {

          this.store.dispatch(QStateStoreEnum.LOADING, false);
          this.presentAlreadyExistingAlert(workspace, formData);

        }
        else {
          this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
            type: NotificationTypeEnum.Error,
            message: _.get(device, 'error.text', 'Error')
          });

          this.store.dispatch(QStateStoreEnum.LOADING, false);
          this.bluetoothService.disconnectFromDeviceWithId(this.connectedDevice.bleUUID);
        }

      }
      else {
        this.deviceApiService.getDeviceSingleConfiguration(workspace, this.connectedDevice.id).subscribe(config => {
          this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
            type: NotificationTypeEnum.Success,
            message: this.translate.instant("PAGE_DEVICE_CONFIGURATION.DEVICE_SUCCESSFULLY_CREATED")
          });

          if (_.get(this.connectedDevice, 'deviceLicense.license', '') === LicenseType.Required) {
            this.modalController.dismiss();
            this.router.navigate(["/license-qr-scan"], { queryParams: { deviceId: this.connectedDevice.id, referrer: 'DEVICE_CONFIGURATION' } });
          }
          else {
            // go to network step
            this.createdApiDevice = config;
            this.currentStep = ConfigurationWizzardEnum.Network;
          }

          this.store.dispatch(QStateStoreEnum.LOADING, false);
        });
      }
    });
  }

  async updateFirmwareAction(message: string) {
    const buttons = [
      {
        text: this.translate.instant('FORMS.OK'),
        handler: () => {
          this.alertController.dismiss();
        }
      }
    ];

    const alert = await this.alertController.create({
      cssClass: 'qstate-alert',
      message: message,
      buttons: buttons,
      backdropDismiss: false
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  private updateDevice(formData: any, workspace: string): void {
    this.deviceApiService.updateDevice(workspace, _.get(this.connectedDevice, 'id'), formData).subscribe(device => {
      
      if (_.get(device, 'error')) {
        const errorMessage = this.translate.instant(`ERROR_MESSAGES.${_.get(device, 'error.key')}`);

        // setTimeout(() => {
        //   this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
        //     type: NotificationTypeEnum.Error,
        //     message: errorMessage ? errorMessage : 'Error'
        //   });
        // }, 2000);
        alert(errorMessage ? errorMessage : 'Error');

        // this.updateFirmwareAction(errorMessage ? errorMessage : 'Error');

        // temporary backend fix
        // this.currentStep = ConfigurationWizzardEnum.Network;
      }
      else {

        if (!this.skipNetwork) {
          this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, {
            type: NotificationTypeEnum.Success,
            message: this.translate.instant("PAGE_DEVICE_CONFIGURATION.DEVICE_SUCCESSFULLY_UPDATED")
          });
        }

        this.currentStep = ConfigurationWizzardEnum.Network;

      }
      // this.store.dispatch(QStateStoreEnum.LOADING, false);
    });
  }

  async presentAlreadyExistingAlert(workspace: string, formData: any) {
    const buttons = [
      {
        text: this.translate.instant('FORMS.CONTINUE'),
        handler: () => {
          this.store.dispatch(QStateStoreEnum.LOADING, true);
          this.addDevice(formData, workspace, true);
        }
      },
      {
        text: this.translate.instant('FORMS.CANCEL'),
        handler: () => {
          this.onCancelForm();
        }
      }
    ];

    const alert = await this.alertController.create({
      cssClass: 'qstate-alert',
      message: this.translate.instant("PAGE_DEVICE_CONFIGURATION.ADD_EXISTING_DEVICE_QUESTION"),
      buttons: buttons,
      backdropDismiss: false
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  setStep(step: ConfigurationWizzardEnum, preventDeviceLoading: boolean = false): void {
    this.currentStep = step;
    this.store.dispatch(QStateStoreEnum.CURRENT_DEVICE_ADD_STEP, step);
    if (this.currentStep === ConfigurationWizzardEnum.Default && !preventDeviceLoading) {
      this.getDevice();
    }
  }
}
