import { Injectable } from '@angular/core';
import { BLE } from '@ionic-native/ble/ngx';
import { BehaviorSubject, forkJoin, Observable, of, Subscription, timer } from 'rxjs';
import { BleServiceEnum } from '../enums/ble-service.enum';
import { BleCharacteristicEnum } from '../enums/ble-characteristic.enum';
import { from } from 'rxjs';
import { NetworkStatusEnum } from '../enums/network-status.enum';
import { CellularStatusEnum } from '../enums/cellular-status.enum';
import { SentryErrorHandler } from '@sentry/angular';
import * as Sentry from '@sentry/angular';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { delay, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BluetoothService {

  healthState: BehaviorSubject<{ bluetooth: string; location: boolean; }> = new BehaviorSubject<any>(null);
  bluetoothStateTrySubscription: Subscription;

  constructor(private ble: BLE, private diagnostics: Diagnostic) { }

  checkIfBluetoothIsEnabled(): Promise<any> {
    return this.ble.isEnabled();
  }

  isLocationEnabled(): Promise<any> {
    return this.ble.isLocationEnabled();
  }

  askToEnableBluetooth(): Promise<any> {
    return this.ble.enable();
  }

  scanBleDevices(scanTime): Observable<any[]> {
    return this.ble.scan([], scanTime);
  }

  stopBleScan(): Observable<any> {
    return from(this.ble.stopScan());
  }

  connectToDeviceWithId(deviceId): Observable<any> {
    return this.ble.connect(deviceId);
  }

  disconnectFromDeviceWithId(deviceId): Observable<any> {
    return from(this.ble.disconnect(deviceId));
  }

  readDeviceCharacteristic(deviceId, serviceId: BleServiceEnum, characteristicID: BleCharacteristicEnum): Observable<any> {
    return from(this.ble.read(deviceId, serviceId, characteristicID));
  }

  writeDeviceCharacteristic(value: string, deviceId, serviceId: BleServiceEnum, characteristicID: BleCharacteristicEnum) {
    let buffer = new Uint8Array(this.convertStringToBytes(value)).buffer;
    return from(this.ble.write(deviceId, serviceId, characteristicID, buffer));
  }

  convertStringToBytes(value: string) {
    var array = new Uint8Array(value.length);
    for (var i = 0, l = value.length; i < l; i++) {
      array[i] = value.charCodeAt(i);
    }
    return array.buffer;
  }

  convertBytesToString(value): string {
    return String.fromCharCode.apply(null, new Uint8Array(value));
  }

  convertByteToNetworkStatusEnum(value): string {
    let enumValue = new Uint8Array(value)[0];
    return NetworkStatusEnum[enumValue];
  }

  convertByteCellularStatus(value): number {
    return new Uint8Array(value)[0];
  }

  convertByteCellularStatusEnum(value): string {
    let enumValue = new Uint8Array(value)[0];
    return CellularStatusEnum[enumValue];
  }

  registerHealthCheck(): void {
    this.bluetoothStateTrySubscription = timer(0, 1000).subscribe(() => {
      this.healthCheck();
    })
  }

  unRegisterHealthCheck(): void {
    this.bluetoothStateTrySubscription.unsubscribe();
  }

  healthCheck(): void {
    this.diagnostics.getBluetoothState().then(bluetoothState => {

      this.diagnostics.isLocationEnabled().then(locationState => {
        this.healthState.next({
          bluetooth: bluetoothState,
          location: locationState
        });
      });

    });
  }
}
