import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { DefaultConfigurationComponent } from "./default-configuration.component";
import { QStateInputComponentModule } from "../qstate-input/qstate-input.module";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponentModule } from "../svg-icon/svg-icon.module";

@NgModule({
    imports:        [ CommonModule, IonicModule, QStateInputComponentModule, TranslateModule, ReactiveFormsModule, FormsModule, SvgIconComponentModule ],
    declarations:   [ DefaultConfigurationComponent ],
    exports:        [ DefaultConfigurationComponent ]
})
export class DefaultConfigurationComponentModule {}