import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class QStateTranslateLoader implements TranslateLoader {

    constructor(private http: HttpClient) { }

    getTranslation(lang: string): Observable<any> {
        const headers = new HttpHeaders().set("preventIntercept", "1");
        return this.http.get(`./assets/translations/${lang}.json`, { headers });
    }
}