import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AdminApiService } from 'src/app/services/admin-api.service';
import { QStateStoreEnum } from 'src/app/store/qstate.enum.store';
import { NotificationTypeEnum } from 'src/app/enums/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { QStateStore } from 'src/app/store/qstate.main.store';
import { ModalInterface } from 'src/app/interfaces/modal.interface';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements ModalInterface {

  get scanEnabled(): boolean {
    if (this.platform.is('android') || this.platform.is('ios') && (this.platform.is('cordova') || this.platform.is('capacitor'))) {
      return true;
    }

    return false;
  }

  @Input() existingWorkspace: any;
  @Input() join: boolean;

  subscriptions: Subscription[] = [];
  selectedWorkspaceModalType: "create" | "join" = "create";
  workspaceAddForm: FormGroup;

  constructor(
    public platform: Platform,
    public modalController: ModalController,
    private cdr: ChangeDetectorRef,
    private adminApiService: AdminApiService,
    private translateService: TranslateService,
    private store: QStateStore,
    private router: Router) { }

  ngOnInit() {

    this.selectedWorkspaceModalType = this.join ? 'join' : 'create';

    this.workspaceAddForm = new FormGroup({
      workspaceId: new FormControl(""),
      name: new FormControl("", [Validators.required]),
      description: new FormControl(""),
      isDefault: new FormControl(false, [Validators.required])
    });

    setTimeout(() => {
      // existing workspace
      if (_.get(this.existingWorkspace, 'workspaceId')) {
        this.workspaceAddForm.get("workspaceId").setValue(_.get(this.existingWorkspace, 'workspaceId', ''));
        this.workspaceAddForm.get("name").setValue(_.get(this.existingWorkspace, 'name', ''));
        this.workspaceAddForm.get("description").setValue(_.get(this.existingWorkspace, 'description', ''));
        this.workspaceAddForm.get("isDefault").setValue(_.get(this.existingWorkspace, 'isDefault', ''));
      }
    });
  }

  onCancelForm() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  onCreateForm() {
    if (this.workspaceAddForm.get("workspaceId").value) {
      if (this.existingWorkspace.isOwner) {
        this.updateWorkspace();
      }
      else {
        this.setWorkspaceDefault();
      }
    }
    else {
      this.createNewWorkspace();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  segmentChanged(event): void {
    this.selectedWorkspaceModalType = event.detail.value;
    this.cdr.detectChanges();
  }

  private createNewWorkspace(): void {
    const workspacePayload = {
      name: this.workspaceAddForm.get("name").value,
      description: this.workspaceAddForm.get("description").value,
      isDefault: this.workspaceAddForm.get("isDefault").value
    }

    this.store.dispatch(QStateStoreEnum.LOADING, true);

    this.adminApiService.createNewWorkspace(workspacePayload).subscribe(resp => {
      if (_.get(resp, "workspaceId")) {

        this.store.dispatch(QStateStoreEnum.LOADING, false);
        this.onCancelForm();

        const notification = {
          message: this.translateService.instant("PAGE_WORKSPACE_CREATE.WORKSPACE_SUCCESSFULLY_CREATED"),
          type: NotificationTypeEnum.Success
        };

        this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, notification);
      }
    });
  }

  private updateWorkspace(): void {
    const workspacePayload = {
      name: this.workspaceAddForm.get("name").value,
      description: this.workspaceAddForm.get("description").value,
      isDefault: this.workspaceAddForm.get("isDefault").value
    }

    this.store.dispatch(QStateStoreEnum.LOADING, true);

    this.adminApiService.updateWorkspace(this.workspaceAddForm.get("workspaceId").value, workspacePayload).subscribe(resp => {
      if (_.get(resp, "workspaceId")) {

        this.store.dispatch(QStateStoreEnum.LOADING, false);
        this.onCancelForm();

        const notification = {
          message: this.translateService.instant("PAGE_WORKSPACE_CREATE.WORKSPACE_SUCCESSFULLY_UPDATED"),
          type: NotificationTypeEnum.Success
        };

        this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, notification);
      }
    });
  }

  private setWorkspaceDefault(): void {
    this.store.dispatch(QStateStoreEnum.LOADING, true);

    this.adminApiService.setWorkspaceAsDefault(this.workspaceAddForm.get("workspaceId").value).subscribe(resp => {
      this.store.dispatch(QStateStoreEnum.LOADING, false);
      this.onCancelForm();

      const notification = {
        message: this.translateService.instant("PAGE_WORKSPACE_CREATE.WORKSPACE_SUCCESSFULLY_UPDATED"),
        type: NotificationTypeEnum.Success
      };

      this.store.dispatch(QStateStoreEnum.NOTIFICATIONS, notification);
    });
  }

  startScanning(): void {
    this.onCancelForm();
    this.router.navigate(['/workspace-qr-scan']);
  }

}
